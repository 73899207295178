import { template as template_e75bdb4c8abb4fa08f79aec012847b54 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e75bdb4c8abb4fa08f79aec012847b54(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
