import { template as template_9dc542a102804978a874ef89df896a1c } from "@ember/template-compiler";
const FKLabel = template_9dc542a102804978a874ef89df896a1c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
